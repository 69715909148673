import { connect } from "react-redux";
import { Link, useLocation } from "@hotel-engine/lib/react-router-dom";

import type { IUser } from "@hotel-engine/types/user";
import { isUserEligibleToViewAndUseRewards } from "@hotel-engine/utilities";
import config from "config";
import { Icon, Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";
import SupportChatLink from "@hotel-engine/app/SupportChatLink";
import { useIsFeatureFlagOn } from "../Experiments";
import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import classNames from "classnames";
import { getFooterSections } from "./constants";

export interface IFooterProps {
  /** the className prop allows this styled component to have their styles be overwritten when reused */
  className?: string;
  /** Current user object */
  user: IUser;
  /** Used to toggle open the customer feedback modal */
  openFeedbackModal: () => void;
}

export const FooterWithRedux = ({ className, user, openFeedbackModal }: IFooterProps) => {
  const { COMPANY_NAME, COMPANY_URL, COMPANY_ABBREVIATION, companySocials } = useCompanyName();
  const MIAWRollout = useExperiment("sf-miaw-rollout", { value: "off" });
  const sfMIAWFeatureFlagEnabled = MIAWRollout.data.value === "on";
  const currencyCode = user.business.currencyCode;
  const { organizationId, supportPhone } = user?.business || {
    disableRewards: false,
    organizationId: null,
    supportPhone: null,
  };
  const location = useLocation();

  const chatLinkReplacementFeatureOn = useIsFeatureFlagOn("chat-link-replacement-members");
  const flexProFeatureOn = useIsFeatureFlagOn("flexpass");
  const isNewDashboard = location.pathname === "/";

  const faqURL =
    organizationId === 273
      ? `https://www.${COMPANY_URL}/texas-faqs`
      : `https://www.${COMPANY_URL}/faq`;

  const sections = getFooterSections({
    user,
    companyUrl: COMPANY_URL,
    faqURL,
    flexProFeatureOn,
  });

  const footerSections = isUserEligibleToViewAndUseRewards(user)
    ? [...sections]
    : sections.filter((section) => section.title !== "Rewards");

  return user ? (
    <Styled.Footer className={className} hideBorder={isNewDashboard}>
      <div className="main-links">
        <div className="link-sections">
          {footerSections.map((section) => (
            <section
              key={section.title}
              className={classNames({ ["four-cols"]: isUserEligibleToViewAndUseRewards(user) })}
            >
              <Typography
                as="h2"
                variant="body/sm-strong"
                color="foregroundPrimary"
                marginBottom={12}
              >
                {section.title}
              </Typography>
              <ul>
                {section.links.map((link) => {
                  if (!link.shouldShow) return null;
                  return link.external ? (
                    <li key={link.label}>
                      <Typography
                        as="a"
                        color="foregroundPrimary"
                        href={link.href}
                        rel="noreferrer"
                        target="_blank"
                        variant="body/sm"
                      >
                        {link.label}
                      </Typography>
                    </li>
                  ) : (
                    <li key={link.label}>
                      <Link to={link.href} onClick={link.onClick}>
                        <Typography variant="body/sm" color="foregroundPrimary">
                          {link.label}
                        </Typography>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </section>
          ))}
        </div>
        <div className="support-section">
          <section className="support-section">
            <Typography
              as="h2"
              variant="body/sm-strong"
              color="foregroundPrimary"
              marginBottom={12}
            >
              Member Support
            </Typography>
            {!!chatLinkReplacementFeatureOn && !sfMIAWFeatureFlagEnabled ? (
              <Styled.SupportChatLinkContainer aria-label="Get Support">
                <Styled.ButtonTitle>Get Support</Styled.ButtonTitle>
                <Styled.ButtonSubtitle>
                  Reach out to Member Support via {<SupportChatLink text="live chat" />}, available
                  24/7.
                </Styled.ButtonSubtitle>
              </Styled.SupportChatLinkContainer>
            ) : (
              <ul>
                <li>
                  <Typography
                    aria-label="Support phone"
                    as="a"
                    color="foregroundPrimary"
                    href={`tel:${supportPhone || config.supportNumber(currencyCode)}`}
                    title="Support number"
                    variant="body/sm"
                  >
                    {supportPhone || config.supportNumber(currencyCode)}
                  </Typography>
                </li>
                <li>
                  <Typography
                    as="a"
                    color="foregroundPrimary"
                    href={`mailto:support@${COMPANY_URL}`}
                    title="Support email"
                    variant="body/sm"
                  >
                    support@{COMPANY_URL}
                  </Typography>
                </li>
              </ul>
            )}
          </section>
          <section className="review-section">
            <button aria-label="Leave Feedback" onClick={openFeedbackModal}>
              <Typography variant="body/sm-strong" color="foregroundPrimary" marginBottom={8}>
                Leave Feedback
              </Typography>
              <Typography variant="body/sm" color="foregroundPrimary">
                Tell us about your experience!
              </Typography>
            </button>
          </section>
        </div>
      </div>
      <section className="social">
        <ul>
          <li>
            <a
              href={companySocials.facebook}
              target="_blank"
              rel="noopener noreferrer"
              title={`${COMPANY_ABBREVIATION} Facebook`}
            >
              <Icon name="facebook" />
            </a>
          </li>
          <li>
            <a
              href={companySocials.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              title={`${COMPANY_ABBREVIATION} LinkedIn`}
            >
              <Icon name="linkedin" />
            </a>
          </li>
          <li>
            <a
              href={companySocials.twitter}
              target="_blank"
              rel="noopener noreferrer"
              title={`${COMPANY_ABBREVIATION} Twitter`}
            >
              <Icon name="x-twitter" />
            </a>
          </li>
        </ul>
        <Typography variant="body/sm" color="foregroundPrimary">
          {COMPANY_NAME}. &copy; {new Date().getFullYear()}. Made in Denver, CO.
        </Typography>
      </section>
    </Styled.Footer>
  ) : null;
};

const mapStateToProps = ({ Auth }) => ({
  user: Auth.user,
});

export default connect(mapStateToProps)(FooterWithRedux);
