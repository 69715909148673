import { ampli } from "ampli";
import { HELP_CENTER, routes } from "@hotel-engine/constants";

import type { IUser } from "@hotel-engine/types/user";

interface IGetFooterSectionArgs {
  user: IUser;
  companyUrl: string;
  faqURL: string;
  flexProFeatureOn: boolean;
}

export interface IFooterSection {
  title: string;
  links: {
    label: string;
    href: string;
    external?: boolean;
    onClick?: () => void;
    shouldShow: boolean;
  }[];
}

export const getFooterSections = ({
  user,
  companyUrl,
  faqURL,
  flexProFeatureOn,
}: IGetFooterSectionArgs): IFooterSection[] => {
  return [
    {
      title: "Company",
      links: [
        {
          label: "About Us",
          href: `https://www.${companyUrl}/about-us`,
          external: true,
          shouldShow: true,
        },
        {
          label: "Careers",
          href: `https://www.${companyUrl}/careers`,
          external: true,
          shouldShow: true,
        },
        {
          label: "Blog",
          href: `https://www.${companyUrl}/blog/`,
          external: true,
          shouldShow: true,
        },
      ],
    },
    {
      title: "Flex",
      links: [
        { label: "Flex FAQ", href: "/flex-overview", external: true, shouldShow: true },
        {
          label: "FlexPro FAQ",
          href: "/flexpro-overview",
          external: true,
          shouldShow: flexProFeatureOn,
        },
      ],
    },
    {
      title: "Support",
      links: [
        { label: "Help Center", href: HELP_CENTER, external: true, shouldShow: true },
        { label: "FAQ", href: faqURL, external: true, shouldShow: true },
        {
          label: "Contact Us",
          href: `https://www.${companyUrl}/contact-us/`,
          external: true,
          shouldShow: true,
        },
        {
          label: "Groups",
          href: routes.groups.base,
          onClick: () =>
            ampli.clickStartNewSpecialBookingRequest({ source: "footer", userId: user?.id }),
          shouldShow: true,
        },
        {
          label: "Terms & Conditions",
          href: `https://www.${companyUrl}/terms-of-service/`,
          external: true,
          shouldShow: true,
        },
      ],
    },
    {
      title: "Rewards",
      links: [
        { label: "About Rewards", href: routes.rewardsEnrollment, shouldShow: true },
        { label: "Rewards FAQ", href: routes.rewardsFaq, shouldShow: true },
        { label: "Rewards Terms", href: routes.rewardsToc, shouldShow: true },
      ],
    },
  ];
};
